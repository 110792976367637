import React, {memo, useContext} from 'react'
import {BiCopy} from 'react-icons/bi'
import { AccountContext, UserInfo } from '../../../context/AccountData'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

interface Props {
  user: UserInfo,
}

const UserInfomation: React.FC<Props> = ({user}: Props): React.ReactElement => {
  const userData = useContext(AccountContext)
    
  return (
    <>
      <div className=' rounded-full overflow-hidden '>
        <Jazzicon  diameter={100} seed={jsNumberForAddress(userData.account.user)} />
        {/* <img src={user.img} alt="User Avatar" className='object-cover w-32 h-32 object-center'/> */}
      </div>
      <div className='flex mt-6 items-center'>
        <p className='text-sm text-primaryColor font-semibold'>
          {user.user}
        </p>
        <button className='ml-2 text-lg opacity-60 hover:opacity-80'>
          <i><BiCopy /></i>
        </button>
      </div>
    </>
  )
}

export default memo(UserInfomation)