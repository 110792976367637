import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AccountContext } from '../../context/AccountData';

interface Props {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ element }: Props): React.ReactElement => {
  const auth = localStorage.getItem('private_route')
  const account = useContext(AccountContext)
  return (
    auth
      ?
      (
        account.account.user === '' ?
          <Navigate to={'/login'} />
          :
          element
      )
      : <Navigate to="/private_route" />
  )
}

export default PrivateRoute